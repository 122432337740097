// Hamburger icon toggle 
jQuery('.hamburger').click(function() {
    jQuery(this).toggleClass('is-active');
    jQuery('#overlay').toggleClass('open');
    jQuery('#ve-mobile-menu').toggleClass('overlay-open');
    jQuery('.overlay-shadow').toggleClass('open');
});

// position arrow next to first slide after page load
function setarrow(){
    if(jQuery('.arrow-left').length) {
        jQuery('.arrow-left').css('top', jQuery("#slidecontrols .selected").offset().top-90);
    }
}

// FAQs page scripts
jQuery(document).ready(function() {
        // Insert the wrapper for the controls
        jQuery('#faq-sections').append('<ul id="slidecontrols" class="hidden-sm hidden-xs"></ul>');
        jQuery('#faq-sections').append('<span class="css-select visible-sm visible-xs"><select id="slideselect" class="form-control visible-sm visible-xs" name="slideselect"></select></span>');

        //For each heading...
        jQuery('#slides div.slide > h3').each(function(){
            // Copy it into the controls
            jQuery(this).clone().appendTo("#slidecontrols");
            jQuery(this).clone().appendTo("#slideselect");
            // and replace the heading markup with list markup, keeping the contained text
            jQuery('#slidecontrols h3').replaceWith('<li><a href="#">' + jQuery(this).text() + '</a></li>');
            jQuery('#slideselect h3').replaceWith('<option>' + jQuery(this).text() + '</option>');
        });
    
        // Hide those H3s
        jQuery('.slide > h3').hide();
    
        //Set the initial state: highlight the first button...
        jQuery('#slidecontrols').find('li:eq(0)').addClass('selected');

        // position arrow next to first slide after page load
        setTimeout(setarrow, 1000);

        //and hide all slides except the first one
        jQuery('#slides').find('> div:eq(0)').nextAll().hide();
    
        //actions that apply on click of any of the buttons
        jQuery('#slidecontrols li').click( function(event) {
    
            //turn off the link so it doesn't try to jump down the page
            event.preventDefault();
    
            //un-highlight the buttons
            jQuery('#slidecontrols li').removeClass();
    
            //hide all the slides
            jQuery('#slides > div').fadeOut(400);
    
            //highlight the current button
            jQuery(this).addClass('selected');

            var arrowPosition = jQuery("#slidecontrols .selected").offset().top-90;

            jQuery('.arrow-left').css('top', arrowPosition);
            //get the index of the current button...
            var index = jQuery('#slidecontrols li').index(this);
    
            //and use that index to show the corresponding slide
            jQuery('#slides > div:eq('+index+')').delay(800).fadeIn();
            
            //update mobile dropdown to match the current selected li 
            jQuery('#slideselect option').eq(index).prop('selected', true);
        });

        // update content based on dropdown value selected for mobile view
        jQuery( "#slideselect" ).change(function() {
            var selected = jQuery("select[name='slideselect'] option:selected").index();
            jQuery('#slides > div').fadeOut(400);
            jQuery('#slides > div:eq('+selected+')').delay(800).fadeIn();
            
            //update desktop li to match the current selected dropdown value
            jQuery('#slidecontrols li').removeClass();
            jQuery('#slidecontrols li:eq('+selected+')').addClass('selected');

        });
});


jQuery( window ).resize(function() { 
    // position arrow next to active slide on page resize
    if(jQuery('.arrow-left').length) {
        jQuery('.arrow-left').css('top', jQuery("#slidecontrols .selected").offset().top-90);
    }
});



// Sticky header
jQuery(window).scroll(function() {
   if (jQuery(this).scrollTop() > 80  && jQuery(window).width() >= '481') {  
        jQuery('#ve-mobile-menu').addClass('sticky');  
   } else if (jQuery(this).scrollTop() < 80  && jQuery(window).width() >= '481') {  
        jQuery('#ve-mobile-menu').removeClass('sticky');
   } 
});

jQuery( window ).resize(function() {     
    if (jQuery(this).scrollTop() > 80){  
       if(jQuery(window).width() >= '481') {
           jQuery('#ve-mobile-menu').addClass('sticky');   
       }
   } else {
       if(jQuery(window).width() >= '481') {
           jQuery('#ve-mobile-menu').removeClass('sticky');
       }
   }  
});

function randomIntFromInterval(min,max){
    return Math.floor(Math.random()*(max-min+1)+min);
}

(function($){
	$(document).ready(function(e) {
		
		// our range slider
		$('.tile-slider').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			fade: true,
			autoplay: true,
			autoplaySpeed: randomIntFromInterval(4000,5000),
			speed: 1500,
			pauseOnHover:false
		});
		
		// our range main slider
		$('.range-slideshow .fader').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			fade: true,
			autoplay: true,
			autoplaySpeed: 4000,
			speed: 1500,
			pauseOnHover:false
		});
		
		 $('.slider-for').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			fade: true,
			asNavFor: '.slider-nav',
			waitForAnimate:false
		});
		$('.slider-nav').slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			asNavFor: '.slider-for',
			focusOnSelect: true,
			waitForAnimate:false
		});
		
		//$('.slider-mob')
		
		$('.slider-mob').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			fade: true,
			//waitForAnimate:false
		});
		
		// sync nav
		$('.slider-mob').on('beforeChange', function(event, slick, currentSlide, nextSlide){
			if(!$('.view-dropdown').hasClass('droped')){
				$('.view-dropdown ul li').removeClass('active')
				$('.view-dropdown ul li[data-index='+ nextSlide +']').trigger('click')
			}
			
		});
		
		// sync withd detail
		$('.slider-for').on('beforeChange', function(event, slick, currentSlide, nextSlide){
			
				$('.product-detailed-desktop').removeClass('active')
				$('.product-detailed-desktop[data-index='+ nextSlide +']').addClass('active')

		});
		
		// mobile nav
		$('.view-dropdown ul li').click(function(e){
			e.preventDefault()
			var $this = $(this)
			var idx = parseFloat($this.attr('data-index'))
			var selected = $this.html()
			
			// go to next slide
			$('.slider-mob').slick('slickGoTo',idx)
			
			$this.siblings('li').removeClass('active')
			$this.addClass('active')
			
			$this.parents('.view-dropdown').removeClass('droped')
			$this.parents('.view-dropdown').find('.holder').html(selected)
			
			$('.product-detailed-mobile').removeClass('active')
			$('.product-detailed-mobile[data-index='+ idx +']').addClass('active')
			
		})
		
		$('.view-dropdown .holder').click(function(e){
			e.preventDefault()
			$(this).parent().toggleClass('droped')
		
		})
		
		// home auto rotate
		setInterval(function(){ 
			$('.home-slideshow .custom-arrow-right').trigger('click')
		}, 7000);
		
	});
})(jQuery)